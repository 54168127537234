export enum AuthExperience {
  Resume,
  Login,
  Signup,
  Recover,
}

export interface AuthStackProps {
  pin: string;
  phone: string;
  authError: string;
  setPin: (pin: string) => void;
  setPhone: (phone: string) => void;
  setAuthError: (error: string) => void;
  setAuthExperience: (exp: AuthExperience) => void;
}
