export interface ChamaRule {
  rule: string;
  description?: string;
}
export type ChamaRules = { [id: string]: ChamaRule };

export type ChamaMember = {
  id: string;
  phone: string;
  role: ChamaMemberRole;
  contributions: number;
  withdrawals: number;
  penalties: number;
};

export enum ChamaMemberRole {
  Admin,
  Member,
}

export type ChamaMembers = { [id: string]: ChamaMember };

export interface Chama {
  id: string;
  name: string;
  description: string;
  members: ChamaMembers;
  rules: ChamaRules;
}

export type CreateChama = Omit<Chama, 'id'> & {
  invites: string[];
};
export type FindChama = Partial<Pick<Chama, 'id' | 'name'>>;
export type DeleteChama = Pick<Chama, 'id'>;
export type DeleteManyChama = { ids: string[] };
export type UpdateChama = {
  id: string;
  updates: Partial<Pick<Chama, 'name' | 'description' | 'members'>>;
};

export interface ChamaInvite {
  chama: Pick<Chama, 'id' | 'name' | 'description'>;
  user: string;
}

export type InviteMembers = Pick<Chama, 'id'> & {
  users: string[];
};
