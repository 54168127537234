import React, { useCallback, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  CloseButton,
  Spacer,
  Button,
  Flex,
  Text,
  useDisclosure,
  Link,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { FEDI_ALPHA_URL, MUTINY_URL } from '../configs';
import { TemplateModal } from './TemplateModal';

export enum AlertStatus {
  Info = 'info',
  Warning = 'warning',
  Success = 'success',
  Error = 'error',
}

export enum AlertId {
  Demo = 'demo',
  Pilot = 'pilot',
  ChamaAdmin = 'chama-admin',
  Generic = 'generic',
}

interface AppAlertProps {
  id: AlertId;
  status: AlertStatus;
  description: JSX.Element | string;
  onClose?: () => void;
}

export const AppAlert = React.memo(function Section({
  status,
  description,
  onClose,
}: AppAlertProps): JSX.Element {
  return (
    <Alert
      status={status}
      variant='subtle'
      display='flex'
      justifyContent='space-between'
    >
      <AlertIcon />
      <AlertDescription width='100%'>{description}</AlertDescription>
      {onClose && (
        <>
          <Spacer />
          <CloseButton
            alignSelf='flex-start'
            position='relative'
            onClick={onClose}
          />
        </>
      )}
    </Alert>
  );
});

interface DeferredAppAlert {
  id: AlertId;
  closed: EpochTimeStamp;
  defer_ms: number;
}

export const useAppAlerts = (): {
  appAlerts: AppAlertProps[];
  hasAppAlert: (alertId: AlertId) => boolean;
  registerAppAlert: (alert: AppAlertProps) => void;
  removeAppAlert: (alertId: AlertId) => void;
  deferAppAlert: (alertId: AlertId, defer_ms: number) => void;
} => {
  const [appAlerts, setAppAlerts] = useState<AppAlertProps[]>([]);
  const [deferredAppAlerts, setDeferredAppAlerts] = useState<
    DeferredAppAlert[]
  >([]);

  const hasAppAlert = useCallback(
    (alertId: AlertId) =>
      !!appAlerts.find((appAlert) => appAlert.id === alertId),
    [appAlerts]
  );

  const registerAppAlert = useCallback(
    (alert: AppAlertProps) => {
      if (appAlerts.find((appAlert) => appAlert.id === alert.id)) {
        return;
      }
      if (
        deferredAppAlerts.find((appAlert) => {
          return (
            appAlert.id === alert.id &&
            appAlert.closed + appAlert.defer_ms > new Date().getTime()
          );
        })
      ) {
        return;
      }
      setAppAlerts([...appAlerts, alert]);
    },
    [appAlerts, deferredAppAlerts, setAppAlerts]
  );

  const deferAppAlert = useCallback(
    (alertId: AlertId, defer_ms: number) => {
      setDeferredAppAlerts([
        ...deferredAppAlerts,
        { id: alertId, closed: new Date().getTime(), defer_ms },
      ]);
    },
    [deferredAppAlerts, setDeferredAppAlerts]
  );

  const removeAppAlert = useCallback(
    (alertId: AlertId) => {
      setAppAlerts(appAlerts.filter((alert) => alert.id !== alertId));
    },
    [appAlerts, setAppAlerts]
  );

  return {
    appAlerts,
    hasAppAlert,
    registerAppAlert,
    removeAppAlert,
    deferAppAlert,
  };
};

export const BitsaccoDemoInfo = React.memo(
  function BitsaccoDemoInfo(): JSX.Element {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
      <Flex
        direction='row'
        wrap={{ base: 'wrap', md: 'nowrap' }}
        alignItems='center'
        justifyContent='space-between'
        width='100%'
      >
        <Text fontSize={{ base: '14px', md: '16px' }}>
          <strong>You are currently on Bitsacco demo. </strong>
          All transactions are for testing purposes only!
        </Text>
        <Button onClick={onOpen} mt={{ base: '2', md: '0' }}>
          learn more
        </Button>
        <TemplateModal
          isOpen={isOpen}
          onClose={onClose}
          header={<Text>Bitsacco Demo</Text>}
          body={
            <>
              <Text>
                This public demo runs on{' '}
                <Link href={MUTINY_URL} isExternal>
                  <strong>Mutinynet Signet</strong> <ExternalLinkIcon />
                </Link>
                . This is a Bitcoin test environment.{' '}
                <strong>
                  All the Bitcoin in this environment have no monetary value.
                </strong>
              </Text>
              <br />
              <Text>
                To test Mpesa deposit experience, you will be using real funds
                from your mobile wallet, but take note that{' '}
                <strong>
                  what you get on the bitcoin side are signet coins
                </strong>
              </Text>
              <br />
              <Text>
                To test Bitcoin lightning deposit experience, you can get some
                signet coins from{' '}
                <Link href={FEDI_ALPHA_URL} isExternal>
                  <strong>this faucet </strong>
                  <ExternalLinkIcon />
                </Link>
              </Text>
              <br />
              <Text>
                We at Bitsacco thank you for helping us test this early
                experience of our platform. Please note,{' '}
                <strong>we are not liable for any loss of funds</strong> at this
                stage. Only use what you can afford to lose.
              </Text>
              <br />
              <Text>
                Happy testing, and thank you for your support and understanding
              </Text>
            </>
          }
          footer={
            <Button onClick={onClose} variant='outline' colorScheme='teal'>
              I acknowledge and understand
            </Button>
          }
        />
      </Flex>
    );
  }
);

export const BitsaccoPilotInfo = React.memo(
  function BitsaccoDemoInfo(): JSX.Element {
    const {
      isOpen: isOpenLearnMore,
      onOpen: onOpenLearnMore,
      onClose: onCloseLearnMore,
    } = useDisclosure();

    return (
      <Flex
        direction='row'
        wrap={{ base: 'wrap', md: 'nowrap' }}
        alignItems='center'
        justifyContent='space-between'
        width='100%'
      >
        <Text fontSize={{ base: '14px', md: '16px' }}>
          <strong>You are currently on Bitsacco Pilot. </strong>
          All transactions are for testing purposes, but can be refunded!
        </Text>
        <Flex
          direction='row'
          wrap={{ base: 'wrap', md: 'nowrap' }}
          alignItems='center'
          gap={2}
        >
          <Button onClick={onOpenLearnMore} mt={{ base: '2', md: '0' }}>
            learn more
          </Button>
        </Flex>
        <TemplateModal
          isOpen={isOpenLearnMore}
          onClose={onCloseLearnMore}
          header={<Text>Bitsacco Pilot</Text>}
          body={
            <>
              <Text>
                Welcome to Bitsacco Pilot. This is a pilot program for early
                users to test the platform.{' '}
              </Text>
              <br />
              <Text>
                You can help us test Bitsacco by creating chamas, inviting
                friends, and making transactions as a Chama.{' '}
              </Text>
              <br />
              <Text>
                We at Bitsacco thank you for helping us test this early
                experience of our platform. Please note,{' '}
                <strong>we are not liable for any loss of funds</strong> at this
                stage. Only use what you can afford to lose.
              </Text>
              <br />
              <Text>
                Happy testing, and thank you for your support and understanding
              </Text>
            </>
          }
          footer={
            <Button
              onClick={onCloseLearnMore}
              variant='outline'
              colorScheme='teal'
            >
              I acknowledge and understand
            </Button>
          }
        />
      </Flex>
    );
  }
);

export const UpdateChamaAdmins = React.memo(function UpdateChamaAdmins({
  recommendedAdmins,
}: {
  admins: number;
  recommendedAdmins: number;
}): JSX.Element {
  return (
    <Flex
      direction='row'
      wrap={{ base: 'wrap', md: 'nowrap' }}
      alignItems='center'
      justifyContent='space-between'
      width='100%'
    >
      <Text fontSize={{ base: '14px', md: '16px' }}>
        <strong>Your chama needs more admins! </strong>
        {`Please configure ${recommendedAdmins} or more admins to manage your chama.`}
      </Text>
    </Flex>
  );
});
