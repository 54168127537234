import { Chama, ChamaMember } from './chama';
import { FxRate } from './fx';
import { User } from './user';

export type ChamaTxReviewer = Pick<ChamaMember, 'id' | 'phone' | 'role'>;

export enum ChamaTxReview {
  Approve,
  Reject,
}

export interface ReviewChamaTx {
  id: string;
  reviewer: ChamaTxReviewer;
  review: ChamaTxReview;
}

export interface ChamaTx {
  id: string;
  type: ChamaTxType;
  path: ChamaTxPath;
  amount: number;
  lightning: {
    invoice: string;
    operationId: string;
  };
  state: ChamaTxState;
  meta: ChamaTxMeta;
  approvals: ChamaTxReviewer[];
  rejections: ChamaTxReviewer[];
  fx: FxRate;
}

export interface ChamaTxMeta {
  chama: string;
  user: string;
  description: string;
  timestamp: Date;
}

export enum ChamaTxType {
  Deposit = 'DEPOSIT',
  Withdrawal = 'WITHDRAWAL',
}

export enum ChamaTxState {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Complete = 'COMPLETE',
  Failed = 'FAILED',
}

export enum ChamaTxPath {
  Lightning = 'LIGHTNING',
  Mpesa = 'MPESA',
}

export type CreateChamaTx = Omit<
  ChamaTx,
  'id' | 'state' | 'rejections' | 'fx'
> & {
  state: ChamaTxState.Pending;
};

export type UpdateChamaTx = {
  id: string;
  updates: Partial<Omit<ChamaTx, 'id' | 'type' | 'path'>>;
};

export type FindChamaTx = Pick<ChamaTx, 'id'>;

export type FindAllChamaTx = {
  chama?: string;
  user?: string;
  type?: ChamaTxType;
  path?: ChamaTxPath;
  state?: ChamaTxState;
};

export type ChamaTxRequest = {
  user: Pick<User, 'id' | 'phone' | 'profile'>;
  chama: Pick<Chama, 'id' | 'name' | 'description'>;
  tx: ChamaTx;
};
