import React, { useCallback } from 'react';
import {
  VStack,
  Card,
  CardHeader,
  CardBody,
  Flex,
  Button,
  Text,
} from '@chakra-ui/react';
import { User, CreateUser } from '@bitsacco/types';
import { isValidPhone, isValidPin, digitizePhone } from '../../utils';
import { PinStack } from './PinStack';
import { useApi } from '../Providers';
import { AuthStackProps, AuthExperience } from './types';

export const SignupStack = ({
  pin,
  phone,
  authError,
  setPin,
  setPhone,
  setAuthError,
  setAuthExperience,
}: AuthStackProps) => {
  const { bitsacco } = useApi();

  const signupUser = useCallback(() => {
    if (!isValidPhone(phone)) {
      setAuthError('invalid phone number');
      return;
    }

    if (!isValidPin(pin)) {
      setAuthError('pin error');
      return;
    }

    (async () => {
      try {
        const user = await bitsacco.request<User, CreateUser>(
          'POST',
          '/user/create',
          {
            phone: digitizePhone(phone),
            pin,
            nostr: null,
            profile: null,
          }
        );

        if (user) {
          setAuthError('');
          return;
        }

        throw 'failed to register user';
      } catch (e) {
        setAuthError(`${e}`);
      }
    })();
  }, [bitsacco, phone, pin, setAuthError]);

  return (
    <VStack>
      <Card align='center' w={'400px'} px={'5'}>
        <CardHeader>
          <Text fontSize='xl' fontWeight='semibold'>
            Sign Up
          </Text>
        </CardHeader>
        <CardBody>
          <PinStack
            {...{
              pin,
              phone,
              authError,
              setPin,
              setPhone,
              setAuthError,
              signupUser,
            }}
          />
        </CardBody>
      </Card>
      <Flex gap='4'>
        <Button
          variant='link'
          onClick={() => setAuthExperience(AuthExperience.Login)}
        >
          Login
        </Button>
        <Button
          variant='link'
          onClick={() => setAuthExperience(AuthExperience.Recover)}
        >
          Recover
        </Button>
      </Flex>
    </VStack>
  );
};
