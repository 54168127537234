import React, { useCallback } from 'react';
import {
  FormHelperText,
  VStack,
  Card,
  CardHeader,
  CardBody,
  FormControl,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react';
import { User, LoginUser } from '@bitsacco/types';
import { isValidPhone, isValidPin, digitizePhone } from '../../utils';
import { PinInputGroup, PhoneInputGroup } from '../InputGroups';
import { useApi, useAuth } from '../Providers';
import { AuthStackProps, AuthExperience } from './types';

export const LoginStack = ({
  pin,
  phone,
  authError,
  setPin,
  setPhone,
  setAuthError,
  setAuthExperience,
}: AuthStackProps) => {
  const { bitsacco } = useApi();
  const { login } = useAuth();

  const loginUser = useCallback(() => {
    if (!isValidPhone(phone)) {
      setAuthError('invalid phone number');
      return;
    }

    if (!isValidPin(pin)) {
      setAuthError('pin error');
      return;
    }

    (async () => {
      try {
        const user = await bitsacco.request<User, LoginUser>(
          'POST',
          '/user/login',
          {
            phone: digitizePhone(phone),
            pin,
          }
        );

        if (user) {
          return login(user);
        }

        throw `login error`;
      } catch (e) {
        setAuthError(`${e}`);
        setPin('');
      }
    })();
  }, [bitsacco, phone, pin, login, setAuthError, setPin]);

  const showEnterPin = useCallback(() => {
    if (phone && isValidPhone(phone)) {
      return (
        <>
          <FormHelperText mt={'5'} mb={'2'}>
            Enter Pin
          </FormHelperText>
          <PinInputGroup
            pin={pin}
            setPin={(pin: string) => {
              setAuthError('');
              setPin(pin);
            }}
          />
        </>
      );
    }

    return <></>;
  }, [phone, pin, setPin, setAuthError]);

  return (
    <VStack>
      <Card align='center' w={'400px'} px={'5'}>
        <CardHeader>
          <Text fontSize='xl' fontWeight='semibold'>
            Log In
          </Text>
        </CardHeader>
        <CardBody>
          <VStack spacing={4}>
            <FormControl>
              <PhoneInputGroup
                phone={phone}
                setPhone={(phone: string) => {
                  setAuthError('');
                  setPhone(phone);
                }}
              />
              {showEnterPin()}
              {authError && (
                <FormHelperText color='red.300'>{authError}</FormHelperText>
              )}
            </FormControl>
            <Button
              variant='solid'
              colorScheme='teal'
              onClick={loginUser}
              isDisabled={!phone || !isValidPin(pin) || !!authError}
              w='100%'
            >
              Continue
            </Button>
          </VStack>
        </CardBody>
      </Card>
      <Flex gap='4'>
        <Button
          variant='link'
          onClick={() => setAuthExperience(AuthExperience.Signup)}
        >
          Signup
        </Button>
        <Button
          variant='link'
          onClick={() => setAuthExperience(AuthExperience.Recover)}
        >
          Recover
        </Button>
      </Flex>
    </VStack>
  );
};
