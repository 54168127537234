import React, { useCallback, useEffect, useState } from 'react';
import {
  ChamaTx,
  ChamaTxType,
  ChamaTxState,
  FindAllChamaTx,
  ChamaTxReview,
  ChamaMember,
  ReviewChamaTx,
} from '@bitsacco/types';
import {
  Flex,
  Heading,
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Badge,
  Text,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { FetchHeadshot } from '../Headshot';
import { useApi } from '../Providers';
import { ChamaViewProps } from './types';
import { FaEllipsisVertical } from 'react-icons/fa6';
import { TOAST_TIMEOUT_MS } from '../../configs';
import { createCopyTextFn, deepEqual } from '../../utils';

const ShortRowLimit = 4;

export const ChamaActivity = React.memo(function ChamaActivity({
  user,
  chama,
  isAdmin,
}: ChamaViewProps) {
  const { bitsacco } = useApi();
  const [transactions, setTransactions] = useState<ChamaTx[]>([]);
  const [showAll, setShowAll] = useState(false);
  const toast = useToast();

  const displayedTransactions = showAll
    ? transactions
    : transactions.slice(0, ShortRowLimit);

  useEffect(() => {
    (async () => {
      try {
        const txs = await bitsacco.request<ChamaTx[], FindAllChamaTx>(
          'POST',
          `/chama/tx/all`,
          {
            chama: chama.id,
          }
        );

        txs && setTransactions(txs.reverse());
      } catch (error) {
        console.error('error fetching transactions:', error);
      }
    })();
  }, [bitsacco, chama]);

  const reviewTx = useCallback(
    (tx: ChamaTx, review: ChamaTxReview) => {
      (async () => {
        try {
          const reviewer = chama.members[user.id];
          if (!reviewer) {
            console.error('error reviewing transaction: member not found');
            toast({
              title: 'Error',
              description: 'Error reviewing transaction',
              status: 'error',
              duration: TOAST_TIMEOUT_MS,
              isClosable: true,
            });
            return;
          }

          await bitsacco.request<ChamaTx, ReviewChamaTx>(
            'POST',
            `/chama/tx/review`,
            {
              id: tx.id,
              reviewer: {
                id: reviewer.id,
                phone: reviewer.phone,
                role: reviewer.role,
              },
              review,
            }
          );
          toast({
            title: 'Success',
            description: 'Successfully reviewed transaction',
            status: 'success',
            duration: TOAST_TIMEOUT_MS,
            isClosable: true,
          });
        } catch (error) {
          console.error('error reviewing transaction:', error);
          toast({
            title: 'Error',
            description: 'Error reviewing transaction',
            status: 'error',
            duration: TOAST_TIMEOUT_MS,
            isClosable: true,
          });
        }
      })();
    },
    [bitsacco, chama, user, toast]
  );

  const cancelTx = useCallback(
    (tx: ChamaTx) => {
      (async () => {
        try {
          await bitsacco.request('PATCH', `/chama/tx/update`, {
            id: tx.id,
            updates: {
              state: ChamaTxState.Failed,
            },
          });
          toast({
            title: 'Success',
            description: 'Cancelled pending transaction',
            status: 'success',
            duration: TOAST_TIMEOUT_MS,
            isClosable: true,
          });
        } catch (error) {
          console.error('error cancelling transaction:', error);
          toast({
            title: 'Error',
            description: 'Error cancelling transaction',
            status: 'error',
            duration: TOAST_TIMEOUT_MS,
            isClosable: true,
          });
        }
      })();
    },
    [bitsacco, toast]
  );

  return (
    <Flex direction='column' gap={4}>
      <Heading size='sm' textTransform='uppercase'>
        Activity
      </Heading>

      <Box
        px={{ base: '', md: '3' }}
        gap={{ base: '6', lg: '6' }}
        overflowX='scroll'
        css={{ scrollbarWidth: 'none' }}
      >
        {displayedTransactions.length > 0 ? (
          <>
            <Table variant='striped'>
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th>Description</Th>
                  <Th isNumeric>Amount (Sats)</Th>
                  <Th>Member</Th>
                  <Th>Type</Th>
                  <Th>Status</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {displayedTransactions.map((tx) => (
                  <Tr key={tx.id}>
                    <Td>{format(new Date(tx.meta.timestamp), 'PPpp')}</Td>
                    <Td>{tx.meta.description}</Td>
                    <Td isNumeric>{(tx.amount / 1000).toFixed(2)}</Td>
                    <Td>
                      <FetchHeadshot user={user} id={tx.meta.user} />
                    </Td>
                    <Td>
                      <Badge
                        colorScheme={getTxTypeColor(tx.type)}
                        variant='outline'
                        borderRadius={5}
                      >
                        {tx.type}
                      </Badge>
                    </Td>
                    <Td>
                      <Badge
                        colorScheme={getTxStateColor(tx.state)}
                        borderRadius={5}
                      >
                        {tx.state}
                      </Badge>
                    </Td>
                    <Td>
                      <ActivityMenu
                        tx={tx}
                        member={chama.members[user.id]}
                        isAdmin={isAdmin}
                        copyTxId={() =>
                          createCopyTextFn(toast)('transaction id', tx.id)
                        }
                        reviewTx={(rv: ChamaTxReview) => reviewTx(tx, rv)}
                        cancelTx={() => cancelTx(tx)}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {transactions.length > ShortRowLimit && (
              <Flex alignItems='center' justifyContent='end' fontFamily='body'>
                <Button mt={4} onClick={() => setShowAll(!showAll)}>
                  {showAll ? 'Show Less' : 'Show More'}
                </Button>
              </Flex>
            )}
          </>
        ) : (
          <Text>no chama activity found</Text>
        )}
      </Box>
    </Flex>
  );
});

const getTxTypeColor = (type: ChamaTxType) => {
  switch (type) {
    case ChamaTxType.Deposit:
      return 'green';
    case ChamaTxType.Withdrawal:
      return 'red';
  }
};

const getTxStateColor = (state: ChamaTxState) => {
  switch (state) {
    case ChamaTxState.Pending:
    case ChamaTxState.Approved:
      return 'teal';
    case ChamaTxState.Rejected:
    case ChamaTxState.Failed:
      return 'red';
    case ChamaTxState.Complete:
      return 'green';
  }
};

interface ActivityMenuProps {
  tx: ChamaTx;
  member: ChamaMember;
  isAdmin: boolean;
  copyTxId: () => void;
  reviewTx: (review: ChamaTxReview) => void;
  cancelTx: () => void;
}

const ActivityMenu = React.memo(function ActivityMenu({
  tx,
  member,
  isAdmin,
  cancelTx,
  copyTxId,
  reviewTx,
}: ActivityMenuProps) {
  return (
    <Menu isLazy>
      <MenuButton
        variant='ghost'
        as={IconButton}
        aria-label='Options'
        icon={<FaEllipsisVertical />}
        size='sm'
        ml={2}
      />
      <MenuList>
        {showApproveMenu(tx, member, isAdmin) && (
          <MenuItem onClick={() => reviewTx(ChamaTxReview.Approve)}>
            Approve
          </MenuItem>
        )}
        {showRejectMenu(tx, member, isAdmin) && (
          <MenuItem onClick={() => reviewTx(ChamaTxReview.Reject)}>
            Reject
          </MenuItem>
        )}
        {showCancelMenu(tx, member) && (
          <MenuItem onClick={cancelTx}>Cancel</MenuItem>
        )}
        <MenuItem onClick={copyTxId}>Copy ID</MenuItem>
      </MenuList>
    </Menu>
  );
});

const showApproveMenu = (
  tx: ChamaTx,
  member: ChamaMember,
  isAdmin: boolean
) => {
  return (
    isAdmin && // user is admin
    tx.meta.user !== member.id && // transaction is not theirs
    (tx.state === ChamaTxState.Pending || tx.state === ChamaTxState.Rejected) && // transaction is pending or rejected
    !hasApprovedTx(tx, member) // they have not yet approved it
  );
};

const showRejectMenu = (tx: ChamaTx, member: ChamaMember, isAdmin: boolean) => {
  return (
    isAdmin && // user is admin
    tx.meta.user !== member.id && // transaction is not theirs
    (tx.state === ChamaTxState.Pending || tx.state === ChamaTxState.Approved) && // transaction is pending or approved
    !hasRejectedTx(tx, member) // they have not yet rejected it
  );
};

const hasApprovedTx = (tx: ChamaTx, member: ChamaMember) => {
  return tx.approvals.find((approval) => deepEqual(approval, member));
};

const hasRejectedTx = (tx: ChamaTx, member: ChamaMember) => {
  return tx.rejections.includes({ ...member });
};

const showCancelMenu = (tx: ChamaTx, member: ChamaMember) => {
  return (
    tx.meta.user === member.id && // transaction is theirs
    (tx.state === ChamaTxState.Pending || tx.state === ChamaTxState.Approved) // transaction is pending or approved
  );
};
