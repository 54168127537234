import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useTheme,
  Flex,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  MpesaTransaction,
  MpesaTractactionState,
  ChamaTxType,
  ChamaTxState,
  ChamaTx,
  ChamaTxPath,
  CreateChamaTx,
  ChamaTxReviewer,
} from '@bitsacco/types';

import {
  POLL_INTERVAL_MS,
  POLL_TIMEOUT_MS,
  TOAST_TIMEOUT_MS,
} from '../../configs';
import { isChamaAdmin, isChamaTarget } from '../../utils';
import { useApi } from '../Providers';
import { TemplateModal } from '../TemplateModal';
import { StateHelperText, TransactionState } from '../TransactionState';
import { LightningDeposit } from './LightningDeposit';
import { MpesaDeposit } from './MpesaDeposit';
import { DepositTarget } from './types';

interface DepositModalProps {
  depositTarget: DepositTarget;
  isOpen: boolean;
  onClose: () => void;
}

export const DepositModal = React.memo(function DepositModal({
  depositTarget,
  isOpen,
  onClose,
}: DepositModalProps): JSX.Element {
  const { bitsacco } = useApi();

  const [tx, setTx] = useState<ChamaTx>();
  const [amount, setAmount] = useState<number>(0);
  const [depositState, setTransactionState] = useState(TransactionState.Create);
  const [stateHelperText, setStateHelperText] = useState<StateHelperText>({
    value: '',
  });
  const [preApprovers, setPreApprovers] = useState<ChamaTxReviewer[]>([]);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const theme = useTheme();
  const toast = useToast();

  useEffect(() => {
    const startTime = Date.now();

    const interval = setInterval(async () => {
      try {
        if (!tx) {
          return;
        }

        if (Date.now() - startTime >= POLL_TIMEOUT_MS) {
          clearInterval(interval);
          if (
            depositState === TransactionState.Pending &&
            tx.path !== ChamaTxPath.Lightning
          ) {
            setTransactionState(TransactionState.Processing);
            setStateHelperText({
              value: 'Deposit in progress. You can safely close this window.',
            });
          }
          return;
        }

        const deposit = await bitsacco.request<
          MpesaTransaction,
          { id: string }
        >('POST', '/chama/tx/find', { id: tx.id });

        if (deposit) {
          switch (deposit.state) {
            case MpesaTractactionState.Processing:
              setTransactionState(TransactionState.Processing);
              setStateHelperText({
                value: 'Deposit in progress. Please wait...',
              });
              break;
            case MpesaTractactionState.Complete:
              toast({
                title: 'Success',
                description: 'Deposit completed successfully',
                status: 'success',
                duration: TOAST_TIMEOUT_MS,
                isClosable: true,
              });
              setTransactionState(TransactionState.Complete);
              setStateHelperText({
                value: 'Deposit completed successfully',
              });
              clearInterval(interval);
              break;
            case MpesaTractactionState.Failed:
              toast({
                title: 'Error',
                description: 'Deposit failed. Please try again',
                status: 'error',
                duration: TOAST_TIMEOUT_MS,
                isClosable: true,
              });
              setTransactionState(TransactionState.Failed);
              setStateHelperText({
                value: 'Deposit failed. Please try again',
                isError: true,
              });
              clearInterval(interval);
              break;
            default:
              break;
          }
        }
      } catch (e) {
        console.error(e);
        setTransactionState(TransactionState.Failed);
        setStateHelperText({
          value:
            'Failed to check transaction status. Please verify manually or contact support',
          isError: true,
        });
        clearInterval(interval);
      }
    }, POLL_INTERVAL_MS);

    return () => {
      clearInterval(interval);
    };
  }, [
    bitsacco,
    tx,
    depositState,
    toast,
    setTransactionState,
    setStateHelperText,
  ]);

  useEffect(() => {
    const { target, user } = depositTarget;
    if (isChamaTarget(target) && isChamaAdmin(target.members, user.id)) {
      const member = target.members[user.id];
      setPreApprovers([
        {
          id: member.id,
          phone: member.phone,
          role: member.role,
        },
      ]);
    }
  }, [depositTarget]);

  const createTx = useCallback(
    (path: ChamaTxPath) => {
      if (tx || !amount) {
        return;
      }

      (async () => {
        const tx = await bitsacco.request<ChamaTx, CreateChamaTx>(
          'POST',
          '/chama/tx/create',
          {
            type: ChamaTxType.Deposit,
            path,
            amount,
            lightning: { invoice: '', operationId: '' },
            meta: {
              chama: depositTarget.target.id,
              user: depositTarget.user.id,
              description: `Deposit ${amount} KES to ${depositTarget.target.name}`,
              timestamp: new Date(),
            },
            approvals: preApprovers,
            state: ChamaTxState.Pending,
          }
        );

        if (tx) {
          setTx(tx);
          toast({
            title: 'Success',
            description:
              'Deposit started. Please wait while we process your transaction',
            status: 'success',
            duration: TOAST_TIMEOUT_MS,
            isClosable: true,
          });
          setTransactionState(TransactionState.Pending);
        } else {
          setStateHelperText({
            value: 'Failed to create transaction',
            isError: true,
          });
        }
      })();
    },
    [bitsacco, tx, amount, depositTarget, preApprovers, toast, setTx]
  );

  const restartDeposit = useCallback(() => {
    setTransactionState(TransactionState.Create);
    setStateHelperText({ value: '' });
    setAmount(0);
    setTx(undefined);
  }, [setTransactionState, setAmount, setTx]);

  const closeDialogue = useCallback(() => {
    restartDeposit();
    onClose();
  }, [restartDeposit, onClose]);

  const getDepositActions = useCallback(() => {
    if (
      depositState === TransactionState.Failed ||
      depositState === TransactionState.Processing ||
      depositState === TransactionState.Complete
    ) {
      return (
        <>
          <Button
            onClick={restartDeposit}
            variant='outline'
            colorScheme='green'
          >
            Start Over
          </Button>
          <Button onClick={closeDialogue} variant='outline' colorScheme='red'>
            Close
          </Button>
        </>
      );
    }

    return (
      <Button onClick={closeDialogue} variant='outline' colorScheme='red'>
        Cancel
      </Button>
    );
  }, [depositState, restartDeposit, closeDialogue]);

  const getModalHeader = useCallback(() => {
    return <Text>Deposit funds to {depositTarget.target.name}</Text>;
  }, [depositTarget]);

  const getModalBody = useCallback(() => {
    return (
      <Flex direction='column' gap='2'>
        <Tabs
          isFitted
          colorScheme={theme.colors.teal[50]}
          onChange={(index) => setTabIndex(index)}
          defaultIndex={tabIndex}
        >
          <TabList mb='1em'>
            <Tab>Use Mpesa</Tab>
            <Tab>Use Lightning</Tab>
          </TabList>
          <TabPanels minH='16em'>
            <TabPanel>
              <Flex
                flexDirection='column'
                gap='5'
                h='100%'
                justify='center'
                align='center'
              >
                <MpesaDeposit
                  amount={amount}
                  depositTarget={depositTarget}
                  depositState={depositState}
                  stateHelperText={stateHelperText}
                  updateAmount={setAmount}
                  createTx={createTx}
                  tx={tx}
                />
              </Flex>
            </TabPanel>
            <TabPanel>
              <Flex flexDirection='column' gap='5' h='100%' justify='center'>
                <LightningDeposit
                  amount={amount}
                  depositTarget={depositTarget}
                  depositState={depositState}
                  stateHelperText={stateHelperText}
                  updateAmount={setAmount}
                  createTx={createTx}
                  tx={tx}
                />
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Text align='center' justifyContent='center'>
          You can deposit any amount between 1 and 1000 Kenya Shillings
        </Text>
      </Flex>
    );
  }, [
    tx,
    amount,
    depositTarget,
    depositState,
    stateHelperText,
    theme,
    tabIndex,
    setAmount,
    setTabIndex,
    createTx,
  ]);

  const getModalFooter = useCallback(() => {
    return <ButtonGroup spacing='2'>{getDepositActions()}</ButtonGroup>;
  }, [getDepositActions]);

  return (
    <TemplateModal
      isOpen={isOpen}
      onClose={onClose}
      header={getModalHeader()}
      body={getModalBody()}
      footer={getModalFooter()}
    />
  );
});
