import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  useColorModeValue,
  useTheme,
  Input,
  Button,
  Text,
  Flex,
  FormControl,
  InputGroup,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { Chama, User } from '@bitsacco/types';
import { useMessages, useSendMessage } from './hooks';
import { sendDirectMessage as notifyNostr } from '../../services';
import { ChamaViewProps } from './types';

export const ChamaCardChat = React.memo(function ChamaCardChat({
  user,
  chama,
}: ChamaViewProps) {
  const theme = useTheme();
  const bg = useColorModeValue(theme.colors.gray[100], theme.colors.gray[500]);

  const [newMessage, setNewMessage] = useState('');

  const { messages, error: messagesError } = useMessages(chama.id);
  const { send: sendMessage, error: sendError } = useSendMessage(chama.id);

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const shouldScroll =
        scrollContainer.scrollHeight - scrollContainer.scrollTop <=
        scrollContainer.clientHeight + 100;

      if (shouldScroll) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
  }, [messages]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    try {
      await sendMessage({
        text: newMessage,
        createdAt: new Date(),
        uid: user?.id,
        displayName: user?.profile?.name,
        chamaId: chama.id,
      });

      notifyBitsacco(newMessage, user, chama).then((response) => {
        response &&
          sendMessage({
            text: 'We have received your message and will respond to you via Whatsapp or text shortly. Thank you :)',
            createdAt: new Date(),
            uid: uuidv4(),
            displayName: 'Bitsacco',
            chamaId: chama.id,
          });
      });

      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  if (messagesError) {
    return <Box>Error loading messages: {messagesError.message}</Box>;
  }

  return (
    <Flex
      direction='column'
      flex={1}
      bg={bg}
      borderRadius='md'
      height='500px'
      p={4}
    >
      <Flex
        direction='column'
        align='stretch'
        overflowY='auto'
        css={{ scrollbarWidth: 'thin' }}
        flex={1}
        gap={4}
        px={2}
        id={`chat-container-${chama.id}`}
        ref={scrollContainerRef}
      >
        {messages.map((message) => (
          <Flex
            key={message.id}
            direction='column'
            alignSelf={message.uid === user?.id ? 'flex-end' : 'flex-start'}
            bg={message.uid === user?.id ? 'teal.200' : 'gray.200'}
            borderRadius='md'
            p={2}
          >
            <Flex justifyContent='space-between' alignItems='center' gap={4}>
              <Text
                fontSize={'sm'}
                letterSpacing={'0.5px'}
                fontFamily={'body'}
                fontWeight={'700'}
                color={'gray.500'}
              >
                {message.displayName}
              </Text>
              <Text
                fontSize={'xs'}
                fontFamily={'body'}
                fontWeight={'700'}
                color={'gray.500'}
              >
                {format(new Date(message.createdAt), 'E p')}
              </Text>
            </Flex>
            <Text color={message.uid === user?.id ? 'black' : 'black'}>
              {message.text}
            </Text>
          </Flex>
        ))}
      </Flex>
      <FormControl>
        <Flex mt={4} gap={2} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
          <InputGroup>
            <Input
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder='Type a message...'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSendMessage();
                }
              }}
              border='2px solid teal'
            />
          </InputGroup>
          <Button
            colorScheme='teal'
            alignSelf={{ base: 'flex-end', md: '' }}
            onClick={handleSendMessage}
          >
            Send
          </Button>
        </Flex>
      </FormControl>
      {sendError && (
        <Text color='red.500'>Error sending message: {sendError.message}</Text>
      )}
    </Flex>
  );
});

const notifyBitsacco = async (
  message: string,
  user: User,
  chama: Chama
): Promise<string> => {
  if (message.includes('@bitsacco')) {
    const notification = {
      message: message,
      sender: {
        id: user.id,
        phone: user.phone,
        profile: user.profile,
      },
      chama: {
        id: chama.id,
        name: chama.name,
        description: chama.description,
        memberCount: Object.keys(chama.members).length,
      },
    };

    return notifyNostr(JSON.stringify(notification))
      .then(
        () =>
          'We have received your message and will respond to you via Whatsapp or text shortly. Thank you :)'
      )
      .catch(() => 'Notifyng Bitsacco failed. Try again');
  }

  return '';
};
