import React, { useCallback, useEffect, useState } from 'react';
import {
  ButtonGroup,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Text,
  useDisclosure,
  Center,
  IconButton,
  Box,
  useTheme,
} from '@chakra-ui/react';
import { BellIcon, ChatIcon } from '@chakra-ui/icons';
import { Chama, User } from '@bitsacco/types';

import {
  AlertId,
  AlertStatus,
  AppAlert,
  UpdateChamaAdmins,
  useAppAlerts,
} from '../AppAlert';
import { DISABLE_DEPOSITS } from '../../configs';
import { adminCount, getAdminRecommendation, isChamaAdmin } from '../../utils';
import { WithdrawTarget, RequestWithdrawModal } from '../withdraw';
import { DepositTarget, DepositModal } from '../deposit';
import { ChamaActivity } from './ChamaActivity';
import { ChamaCardMain } from './ChamaCardMain';
import { ChamaCardChat } from './ChamaCardChat';

interface ChamaCardProps {
  user: User;
  chama: Chama;
  setFetchChamas: (fetchChamas: boolean) => void;
}

enum CardView {
  Main,
  Activity,
  Chat,
}

export const ChamaCard = React.memo(function ChamaCard({
  user,
  chama,
  setFetchChamas,
}: ChamaCardProps) {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [view, setView] = useState<CardView>(CardView.Main);

  const theme = useTheme();

  const {
    isOpen: showDepositModal,
    onOpen: onOpenDepositModal,
    onClose: onCloseDepositModal,
  } = useDisclosure();

  const {
    isOpen: showWithdrawModal,
    onOpen: onOpenWithdrawModal,
    onClose: onCloseWithdrawModal,
  } = useDisclosure();

  const { appAlerts, registerAppAlert } = useAppAlerts();

  useEffect(() => {
    setIsAdmin(isChamaAdmin(chama.members, user.id));
  }, [chama, user, setIsAdmin]);

  useEffect(() => {
    const admins = adminCount(chama.members);
    const recommendedAdmins = getAdminRecommendation(chama.members, admins);

    if (recommendedAdmins > 0) {
      registerAppAlert({
        id: AlertId.ChamaAdmin,
        status: AlertStatus.Warning,
        description: (
          <UpdateChamaAdmins
            admins={admins}
            recommendedAdmins={recommendedAdmins}
          />
        ),
      });
    }
  }, [chama, registerAppAlert]);

  const transactionTarget: DepositTarget | WithdrawTarget = {
    target: chama,
    user,
  };

  const getCardHeader = useCallback(() => {
    return (
      <Flex
        flexDirection='row'
        flexWrap={{ base: 'wrap', lg: 'nowrap' }}
        justifyContent='space-between'
      >
        <Flex
          gap={2}
          alignItems='center'
          flexDirection='row'
          flexWrap={{ base: 'wrap', lg: 'nowrap' }}
          flexGrow={1}
        >
          <Heading size='md'>{chama.name}</Heading>
          <Text>{chama.description}</Text>
        </Flex>
        <Flex
          gap={2}
          alignItems='end'
          flexDirection='row'
          my={{ base: 2, lg: 0 }}
          flexWrap={{ base: 'wrap', lg: 'nowrap' }}
        >
          <IconButton
            aria-label='show chama chat'
            icon={<ChatIcon />}
            borderRadius='50%'
            onClick={() =>
              setView(view === CardView.Chat ? CardView.Main : CardView.Chat)
            }
            border={
              view === CardView.Chat
                ? `2px solid ${theme.colors.teal[500]}`
                : 'none'
            }
          />
          <IconButton
            aria-label='show chama activity'
            icon={<BellIcon />}
            borderRadius='50%'
            onClick={() =>
              setView(
                view !== CardView.Activity ? CardView.Activity : CardView.Main
              )
            }
            border={
              view === CardView.Activity
                ? `2px solid ${theme.colors.teal[500]}`
                : 'none'
            }
          />
        </Flex>
      </Flex>
    );
  }, [chama, view, theme, setView]);

  const getCardBody = useCallback(() => {
    switch (view) {
      case CardView.Main:
        return <ChamaCardMain user={user} chama={chama} isAdmin={isAdmin} />;
      case CardView.Activity:
        return <ChamaActivity user={user} chama={chama} isAdmin={isAdmin} />;
      case CardView.Chat:
        return <ChamaCardChat user={user} chama={chama} isAdmin={isAdmin} />;
    }
  }, [view, user, chama, isAdmin]);

  return (
    <>
      <Card variant='elevated' colorScheme='teal' boxShadow='dark-lg'>
        <CardHeader>{getCardHeader()}</CardHeader>

        <Center>
          <Divider orientation='horizontal' border={'1px solid teal'} />
        </Center>

        <Box w={'100%'}>
          {appAlerts.map((alert, idx) => (
            <AppAlert {...alert} key={idx} />
          ))}
        </Box>

        <CardBody>{getCardBody()}</CardBody>

        <Center>
          <Divider orientation='horizontal' border={'1px solid teal'} />
        </Center>

        <CardFooter>
          <ButtonGroup
            w='100%'
            display='flex'
            flexDirection='row'
            flexWrap={{ base: 'wrap', lg: 'nowrap' }}
            justifyContent={{ base: 'center', lg: 'start' }}
            gap={'4'}
            spacing={{ base: '0', lg: '4' }}
          >
            <Button
              height={'45px'}
              minW={'150px'}
              flexGrow={1}
              variant='solid'
              colorScheme='teal'
              onClick={onOpenDepositModal}
              isDisabled={DISABLE_DEPOSITS}
            >
              Deposit Funds
            </Button>
            <Button
              height={'45px'}
              minW={'150px'}
              flexGrow={1}
              variant='solid'
              colorScheme='teal'
              onClick={onOpenWithdrawModal}
              m={0}
            >
              Request Withdrawal
            </Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
      <DepositModal
        depositTarget={transactionTarget}
        isOpen={showDepositModal}
        onClose={onCloseDepositModal}
      />
      <RequestWithdrawModal
        chama={chama}
        user={user}
        isAdmin={isAdmin}
        isOpen={showWithdrawModal}
        onClose={() => {
          setFetchChamas(true);
          onCloseWithdrawModal();
        }}
      />
    </>
  );
});
