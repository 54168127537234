import React from 'react';
import {
  IconButton,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tag,
  TagLabel,
  Text,
  useTheme,
  Flex,
} from '@chakra-ui/react';
import { ChamaMember, ChamaMemberRole, ChamaRule, User } from '@bitsacco/types';
import { FetchHeadshot } from '../Headshot';
import { FaEllipsisVertical } from 'react-icons/fa6';
import { ComingSoon } from '../ComingSoon';

interface MemberInfoProps {
  member: ChamaMember;
  user: User;
  showMenu: boolean;
  configureAdmin: (member: ChamaMember) => void;
  removeMember: () => void;
}

export const MemberInfo = React.memo(function MemberInfo({
  member,
  user,
  showMenu,
  configureAdmin,
  removeMember,
}: MemberInfoProps) {
  const theme = useTheme();

  const isMe = user.id === member.id;
  const isAdmin = member.role === ChamaMemberRole.Admin;

  const getLabel = () => {
    if (isMe && isAdmin) {
      return 'You ( admin )';
    }

    if (isMe) {
      return 'You';
    }

    if (isAdmin) {
      return `${member.phone} ( admin )`;
    }

    return member.phone;
  };

  return (
    <Tag
      borderRadius={20}
      borderColor={theme.colors.gray[300]}
      borderWidth={2}
      minW='fit-content'
      px={3}
      py={2}
    >
      <FetchHeadshot user={user} id={member.id} size='sm' ml={-1} mr={2} />
      <TagLabel fontSize={'bold'}>{getLabel()}</TagLabel>
      {showMenu && (
        <MemberMenu
          isMe={isMe}
          isAdmin={isAdmin}
          assignAdmin={() =>
            configureAdmin({ ...member, role: ChamaMemberRole.Admin })
          }
          dismissAdmin={() =>
            configureAdmin({ ...member, role: ChamaMemberRole.Member })
          }
          removeMember={removeMember}
        />
      )}
    </Tag>
  );
});

interface MemberMenuProps {
  isMe: boolean;
  isAdmin: boolean;
  assignAdmin: () => void;
  dismissAdmin: () => void;
  removeMember: () => void;
}

const MemberMenu = React.memo(function MemberMenu({
  isMe,
  isAdmin,
  assignAdmin,
  dismissAdmin,
}: MemberMenuProps) {
  return (
    <Menu isLazy>
      <MenuButton
        variant='ghost'
        as={IconButton}
        aria-label='Options'
        icon={<FaEllipsisVertical />}
        size='sm'
        ml={2}
      />
      <MenuList>
        {isAdmin ? (
          <MenuItem onClick={dismissAdmin}>
            {isMe ? 'Resign' : 'Dismiss'} as admin
          </MenuItem>
        ) : (
          <MenuItem onClick={assignAdmin}>Make chama admin</MenuItem>
        )}
        <MenuItem>
          <Flex align='center' justify='space-between' gap={2}>
            {isMe ? 'Exit this' : 'Remove from'} chama
            <ComingSoon />
          </Flex>
        </MenuItem>
      </MenuList>
    </Menu>
  );
});

export const RuleInfo = React.memo(function RuleInfo({
  rule,
  description,
}: ChamaRule) {
  return (
    <ListItem>
      <Text fontSize='md'>{rule}</Text>
      {description && <Text fontSize='sm'>{description}</Text>}
    </ListItem>
  );
});
