import NDK, {
  NDKEvent,
  NDKNip07Signer,
  NDKPrivateKeySigner,
  NDKSigner,
  NDKUser,
} from '@nostr-dev-kit/ndk';
import { generateSecretKey, getPublicKey } from 'nostr-tools/pure';
import { npubEncode } from 'nostr-tools/nip19';
import { bytesToHex, hexToBytes } from '@noble/hashes/utils';
import { NostrId, NostrIdUnsafe } from '@bitsacco/types';

import {
  setLocalValue,
  BITSACCCO_NOSTR_PRIVATE_KEY,
  getLocalValue,
} from './storage';

const ReliableRelays = [
  'wss://relay.damus.io',
  'wss://nostr.mutinywallet.com',
  'wss://relay.nostr.bg',
  'wss://relay.snort.social',
];

export const initializeNostr = async (
  connect = false,
  signer: NDKSigner = new NDKNip07Signer()
): Promise<NDK> => {
  const ndk = new NDK({
    explicitRelayUrls: ReliableRelays,
    signer,
  });

  if (connect) {
    await ndk.connect();
  }

  return ndk;
};

export const createNewNostrId = async (): Promise<NostrIdUnsafe> => {
  const sec = generateSecretKey();
  const pub = getPublicKey(sec);

  // Persist the secret key in local storage
  const hsec = bytesToHex(sec);
  setLocalValue(BITSACCCO_NOSTR_PRIVATE_KEY, hsec);

  return {
    hpub: pub,
    hsec,
  };
};

export const getNostrIdFromLocal = async (): Promise<NostrIdUnsafe> => {
  const hsec = getLocalValue(BITSACCCO_NOSTR_PRIVATE_KEY);

  if (hsec) {
    const sec = hexToBytes(hsec);
    const hpub = getPublicKey(sec);
    const npub = npubEncode(hpub);

    return {
      hpub,
      npub,
      hsec,
    };
  }

  return {};
};

export const getNostrIdFromAddress = async (
  address: string,
  ndk: NDK
): Promise<NostrId> => {
  const raddr = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (raddr.test(address)) {
    const user = await ndk.getUserFromNip05(address);

    if (!user) {
      return { address };
    }

    const npub = user.npub;
    const hpub = user.pubkey;

    return {
      address,
      npub,
      hpub,
    };
  }

  return { address };
};

export const sendDirectMessage = async (message: string) => {
  try {
    let sender = await getNostrIdFromLocal();
    if (!sender.hpub) {
      sender = await createNewNostrId();
    }

    const signer = new NDKPrivateKeySigner(sender.hsec);

    const ndk = await initializeNostr(true, signer);
    const pubkey =
      '1b1a630871342cfc401dfa61892206855ffc0c9cc211694eb9615b4323d6ac9f';

    const dm = new NDKEvent(ndk, {
      kind: 4,
      content: message,
      tags: [['p', pubkey]],
      created_at: Math.floor(Date.now() / 1000),
      pubkey: sender.hpub!,
    });

    const user = new NDKUser({ pubkey });
    dm.encrypt(user);

    return publishEventWithRetry(dm, 5);
  } catch (error) {
    console.error('Error sending direct message:', error);
    throw error;
  }
};

const publishEventWithRetry = async (dm: NDKEvent, maxAttempts = 3) => {
  let attempts = 0;
  while (attempts < maxAttempts) {
    try {
      await dm.publish();
      console.log('Published Nostr event');
      return;
    } catch (error) {
      attempts++;
      if (attempts >= maxAttempts) {
        throw new Error(
          `Failed to publish Nostr event after ${maxAttempts} attempts: ${error}`
        );
      }
      console.warn(`Publish attempt ${attempts} failed. Retrying...`);
      await new Promise((resolve) => setTimeout(resolve, 1000 * attempts));
    }
  }
};
