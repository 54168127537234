import React, { useState, useCallback } from 'react';
import {
  Box,
  Button,
  Flex,
  FormHelperText,
  FormControl,
  Text,
} from '@chakra-ui/react';
import { ChamaTxPath } from '@bitsacco/types';

import { AmountInputGroup, PhoneInputGroup } from '../InputGroups';
import { TransactionState, TransactionStateTracker } from '../TransactionState';
import { DepositProps } from './types';

export const MpesaDeposit = React.memo(function MpesaDeposit({
  tx,
  amount,
  depositTarget,
  depositState,
  stateHelperText,
  updateAmount,
  createTx,
}: DepositProps) {
  const [phone, setPhone] = useState<string>(depositTarget.user.phone);

  const getFormHelperText = useCallback(() => {
    if (stateHelperText.isError) {
      return (
        <FormHelperText color='red.300'>{stateHelperText.value}</FormHelperText>
      );
    }

    return (
      <FormHelperText>
        we will send an mpesa request to the phone number you enter above
      </FormHelperText>
    );
  }, [stateHelperText]);

  if (tx?.path === ChamaTxPath.Lightning) {
    return (
      <Text>
        Lightning deposit in progress... please switch to the Lightning tab
      </Text>
    );
  }

  if (depositState === TransactionState.Create) {
    return (
      <Flex flexDirection='column' gap='5' h='100%' justify='center'>
        <FormControl>
          <Box pb='5'>
            <AmountInputGroup
              amount={amount}
              setAmount={(amnt) => {
                if (amnt > 1000) {
                  return;
                }
                updateAmount(amnt);
              }}
              getFormHelperText={(amountError?: string) => {
                return amountError ? (
                  <FormHelperText color='red.300'>{amountError}</FormHelperText>
                ) : (
                  <></>
                );
              }}
            />
          </Box>
          <Box pb='2'>
            <PhoneInputGroup phone={phone} setPhone={setPhone} />
          </Box>
          {getFormHelperText()}
        </FormControl>
        <Button
          onClick={() => createTx(ChamaTxPath.Mpesa)}
          variant='solid'
          colorScheme='green'
        >
          Send Request
        </Button>
      </Flex>
    );
  }

  return (
    <TransactionStateTracker
      transactionState={depositState}
      stateHelperText={stateHelperText}
      progress={{
        isIndeterminate: true,
        thickness: '4px',
        color: 'green.300',
        size: '3em',
      }}
      icon={{ boxSize: '4em' }}
    />
  );
});
