import React, { useState } from 'react';
import { AvatarProps, Avatar } from '@chakra-ui/react';
import { FindUser, User } from '@bitsacco/types';
import { useApi } from './Providers';

interface HeadshotProps extends AvatarProps {
  user: User;
}

export const Headshot = React.memo(function Headshot({
  user,
  ...props
}: HeadshotProps): JSX.Element {
  return (
    <Avatar
      {...props}
      name={user.profile?.name || generateNameFromPhone(user.phone)}
      src={user.profile?.avatar}
    />
  );
});

type FetchHeadshotProps = Omit<HeadshotProps, 'user'> & {
  id: string;
  user: User;
};

export const FetchHeadshot = React.memo(function FetchHeadshot({
  id,
  user,
  ...props
}: FetchHeadshotProps): JSX.Element {
  const { bitsacco } = useApi();
  const [usr, setUsr] = useState<User | undefined>();

  React.useEffect(() => {
    if (user.id === id) {
      return setUsr(user);
    }

    (async () => {
      try {
        const user = await bitsacco.request<User, FindUser>(
          'POST',
          '/user/find',
          {
            id,
          }
        );
        setUsr(user);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    })();
  }, [bitsacco, user, id]);

  return usr ? (
    <Headshot user={usr} {...props} />
  ) : (
    <Avatar {...props} bg='teal.500' />
  );
});

function generateNameFromPhone(phoneNumber: string): string {
  const digits = phoneNumber.replace(/\D/g, '');
  const lastSix = digits.slice(-6);

  const letterMap = 'ABCDEFGHIJ';
  const letters = lastSix
    .split('')
    .map((digit) => letterMap[parseInt(digit)])
    .join('');
  return `Member-${letters}`;
}
